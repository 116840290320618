import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import './App.css';
import About from './pages/about/About';
import Projects from './pages/projects/Projects';
import Industries from './pages/industries/Industries';
import Services from './pages/services/Services';
import Technologies from './pages/technologies/Technologies';
import Casestudies from './pages/casestudies/CaseStudies';
import CaseDescription from './pages/casestudies/CaseDescription';
import UseScrollToTop from './components/scroll_top/ScrollTop';

import Contact from './pages/contact/Contact';
import Career from './pages/career/Career';
import JobDescription from './pages/career/JobDescription';
import ApplyForJob from './pages/career/ApplyForJob';
import ThankYou from './pages/contact/ThankyouPage';

function App() {
  return (
    <Router>
      <UseScrollToTop />
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/services" element={<Services />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/case-studies" element={<Casestudies />} />
            <Route path="/case-study/:slug" element={<CaseDescription/>} />
            <Route path="/career" element={<Career />} />
            <Route path="/jobs/:slug" element={<JobDescription />} />
            <Route path="/apply/jobs/:slug" element={<ApplyForJob />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
          <Footer />
        </div>
    </Router>
  );
}

export default App;
