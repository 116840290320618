import React from 'react'

import HeroSection from '../../components/heroSection/Herosection';
import App from '../../components/customers/Customers'
import './About.css'
import '../home/Home.css'

const About = () => {
  return (
    <div className='about_page'>
      <HeroSection title="About" />

      <div className='about_container'>

        <div className='header'>
          <h1 className="page_title">About Our Company</h1>
          <div className='border'></div>
          <p className="subtitle"> Discover Our Mission, Values, and Why You Should Choose Us </p>
        </div>

        <div className='content_wrapper'>

          <div className='company_image'>
            <img src='/assets/know-us.jpg' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>Get to Know Us</h1>
            <p className='text_bold'>NeuroOeans AI is a software service provider that specializes in providing Artificial Intelligence (AI) solutions to businesses across various industries. Our team of experts leverages the latest advancements in AI technology to deliver innovative solutions that help companies make better decisions, increase efficiency, and stay ahead of the competition</p>
            <p className='text_normal'>At NeuroOeans AI, we understand the challenges faced by businesses and strive to provide tailored AI solutions that meet the specific needs of our clients. Our goal is to help companies leverage the power of AI to drive growth, increase profitability, and enhance customer satisfaction. We believe that AI holds tremendous potential to transform the way businesses operate and are committed to staying at the forefront of innovation. Our team is dedicated to delivering results and providing exceptional customer service, making NeuroOeans AI a trusted partner for companies looking to harness the power of AI.</p>
          </div>
        </div>

        <div className='content_wrapper reverse'>

          <div className='company_image'>
            <img src='/assets/our_vision.jpg' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>Our Vision</h1>
            <p className='text_normal'>Our vision is to revolutionize industries by integrating AI into every facet of business operations. We aim to be pioneers in AI innovation, driving transformative change and fostering sustainable growth. By focusing on cutting-edge technologies and personalized solutions, we aspire to empower businesses to achieve unparalleled efficiency, agility, and success. Our commitment is to be a beacon of excellence and a catalyst for progress, ensuring our clients stay ahead in an increasingly competitive landscape. We are dedicated to shaping a future where AI seamlessly enhances every business process and experience.</p>
          </div>
        </div>

        <div className='content_wrapper'>

          <div className='company_image'>
            <img src='/assets/what-we-believe.png' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>What We Believe</h1>
            <p className='text_normal'>We believe in forging strong partnerships with our clients, built on trust, transparency, and collaboration. We celebrate the transformative power of AI, striving to innovate and lead in the ever-evolving landscape of artificial intelligence. Our commitment to excellence extends beyond technology; it encompasses a deep dedication to understanding and fulfilling our clients' unique needs. Together with our partners, we embrace the future with enthusiasm, harnessing AI's potential to drive remarkable advancements and create lasting value.</p>
          </div>
        </div>

        <div className='why_us'>
          <h1 className='title'>Why Choose NeuroOceans AI</h1>
          <p className='sub_title'>We create 360-degree solutions that target and simplify multiple organizational challenges, all at once!</p>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/quality_first.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>A quality-first company</h2>
              <p className='text_normal'>At NeuroOceans AI, we specialize in delivering top-tier software solutions and services tailored to meet the unique needs of our clients. Our commitment is to ensure that every investment in IT yields tangible business value. We pride ourselves on fostering healthy project processes, driven by professional and motivated teams, facilitating effective communication between NeuroOceans AI, your teams, and stakeholders.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/fast_stable.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Fast development while retaining application stability</h2>
              <p className='text_normal'>At NeuroOceans AI, we've curated a suite of management and technological strategies enabling us to roll out fresh application iterations every 2–3 weeks, with the flexibility to implement minor updates multiple times daily. Our success stems from an iterative development ethos, bolstered by Agile project management methodologies, and underpinned by cloud-native architectures and cutting-edge infrastructure management techniques.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/commitment.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Commitment to excellence</h2>
              <p className='text_normal'>At NeuroOceans AI, we delve beyond conventional strategies to elevate customer satisfaction and enhance performance through our cutting-edge IT services and innovative solutions. Our proficiency across four crucial dimensions: Customer References, Delivery Excellence (Awards & Certifications), Programs for Innovation, and Corporate Social Responsibility.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/top_security.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Top-notch security</h2>
              <p className='text_normal'>With 10 years of dedicated service in the realm of IT, NeuroOceans AI stands as a beacon of reliability and security. Throughout our journey, not a single breach has compromised the trust of our clients. We pledge unwavering protection for our customers' invaluable data. At NeuroOceans AI, we spare no effort in fortifying our defenses. Our strategy hinges on diligent IT asset management, fostering a secure environment, enforcing strict access controls, and employing formidable encryption algorithms. Continuous monitoring of our IT infrastructure is paramount, ensuring swift detection and response to any potential threats.</p>
            </div>
          </div>

        </div>

      </div>

      <App />
    </div>
  )
}

export default About
