import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import './Herosection.css'

const HeroSection = ({ title }) => {
  return (
    <div className='hero_section'>
      <div className='hero_wrapper'>
        <h1>{title}</h1>
        <Link to={'/'}>
          <span><FontAwesomeIcon icon={faHouse} /> Home </span>
        </Link>
        <span><b> : : </b>{title}</span>
      </div>
    </div>
  );
}

export default HeroSection;