import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

import HeroSection from '../../components/heroSection/Herosection';
import Technology from '../../components/technologies/Technologies'
import App from '../../components/customers/Customers'
import industries from '../../jsondata/industries.json'
import "./Industries.css"

const Industries = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className='industries_page'>

            <HeroSection title="Industries" />

            <div className='industries_container'>
                <div className='header'>
                    <h1 className="page_title">Transforming Industries with AI Solutions</h1>
                    <div className='border'></div>
                    <p className="subtitle"> Harnessing AI's Potential to Revolutionize Healthcare, Pharmaceuticals, and Hospital Management </p>
                </div>
                {/* <h1 className='hero_title'>Industries We Serving</h1>
                <span class="hr"></span> */}
                <div className='tabs_wrapper'>
                    <div className="tab_menu">
                        {industries.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab ${activeTab === index ? 'active' : ''}`}
                                onClick={() => setActiveTab(index)}
                                style={{ marginLeft: index === 0 ? 0 : undefined }}
                            >
                                {tab.title}
                            </div>
                        ))}
                    </div>

                    <div className='content_wrapper'>
                        <div className='tab_detail'>
                            <h2>{industries[activeTab].heading}</h2>
                            <p>{industries[activeTab].description}</p>
                            <ul>
                                {industries[activeTab].items.map((item, index) => (
                                    <li key={index}><FontAwesomeIcon icon={faMinus} />{item}</li>
                                ))}
                            </ul>
                            <div className='button'>
                                <Link to={'/contact'}>SHARE YOUR REQUIREMENTS</Link>
                            </div>
                        </div>
                        <div className='tab_image'>
                            <img src={industries[activeTab].image} alt='Industry' />
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <Technology />
            <App />
        </div>
    )
}

export default Industries