import React from 'react'
import { Link } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./AboutCards.css"
import SectionTitle from '../section_title/SectionTitle'
import CardSlider from './Cards'

const AboutCards = () => {
    return (
        <section className='about_section card_section'>
            <div className='about_wrapper'>
                <SectionTitle start={'03'} end={'06'} title={'We Can Help You With'} />
                <CardSlider />
            </div>
        </section >
    )
}

export default AboutCards