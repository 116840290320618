import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import HeroSection from '../../components/heroSection/Herosection';
import jobs from '../../jsondata/job_post.json';
import './Career.css';

const ApplyForJob = () => {
    const { slug } = useParams();
    const job = jobs.find(job => job.Slug === slug);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        gender: '',
        skills: '',
        resume: null
    });

    const [errors, setErrors] = useState({});
    const formRefs = {
        firstName: useRef(null),
        lastName: useRef(null),
        email: useRef(null),
        phone: useRef(null),
        address: useRef(null),
        gender: useRef(null),
        skills: useRef(null),
        resume: useRef(null)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            resume: e.target.files[0]
        });
        if (errors.resume) {
            setErrors({
                ...errors,
                resume: ''
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First Name is required';
        if (!formData.lastName) newErrors.lastName = 'Last Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone Number is required';
        } else if (!/^\+?[\d\s.-]{11,}$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone number format';
        }

        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.gender) newErrors.gender = 'Select your gender';
        if (!formData.skills) newErrors.skills = 'Atleast one skill is required';
        if (formData.resume) {
            const allowedTypes = [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/pdf'
            ];

            if (!allowedTypes.includes(formData.resume.type)) {
                newErrors.resume = 'File must be in .doc, .docx, or .pdf format';
            }

            if (formData.resume.size > 2 * 1024 * 1024) {
                newErrors.resume = 'File must be less than 2MBs';
            }
        } else {
            newErrors.resume = 'Please upload your resume';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        // By using useRef screen will scroll to first field were error is encountring
        if (Object.keys(validationErrors).length > 0) {
            const firstErrorElement = formRefs[Object.keys(validationErrors)[0]].current;

            if (firstErrorElement) {

                // as navbar is position: "fixed", screen will scroll to first error field including height of navbar.
                const navbarHeight = -140;
                const spaceFromTop = firstErrorElement.getBoundingClientRect().top + window.scrollY + navbarHeight;

                window.scrollTo({ top: spaceFromTop, behavior: 'smooth' });
            }

            return;
        }

        // Show form data in console if no error occur.
        console.log(formData);
    };



    const handleDropZoneClick = () => {
        document.getElementById('resume').click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            resume: e.dataTransfer.files[0]
        });
        if (errors.resume) {
            setErrors({
                ...errors,
                resume: ''
            });
        }
    };

    if (!job) {
        return <div>Job not found</div>;
    }

    return (
        <div className='apply_page'>
            <HeroSection title="Career" />
            <div className='desc_container'>
                <div className='header'>
                    <div>
                        <h1 className="title">{job.Title}</h1>
                        <p><span className='category'>{job.Category}</span> -- <span className='location'>{job.Location}</span></p>
                    </div>
                </div>
                <hr className='header_separator' />
                <div className='job_form'>
                    <h2 className='form_title'>Job Application</h2>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='form_group'>
                            <input
                                type='text'
                                id='firstName'
                                name='firstName'
                                placeholder='First Name*'
                                style={errors.firstName ? { borderColor: 'red' } : {}}
                                value={formData.firstName}
                                onChange={handleChange}
                                ref={formRefs.firstName}
                            />
                            {errors.firstName && <p className='error_line'>{errors.firstName}</p>}
                        </div>
                        <div className='form_group'>
                            <input
                                type='text'
                                id='lastName'
                                name='lastName'
                                placeholder='Last Name*'
                                style={errors.lastName ? { borderColor: 'red' } : {}}
                                value={formData.lastName}
                                onChange={handleChange}
                                ref={formRefs.lastName}
                            />
                            {errors.lastName && <p className='error_line'>{errors.lastName}</p>}
                        </div>
                        <div className='form_group'>
                            <input
                                type='text'
                                id='email'
                                name='email'
                                placeholder='Email*'
                                style={errors.email ? { borderColor: 'red' } : {}}
                                value={formData.email}
                                onChange={handleChange}
                                ref={formRefs.email}
                            />
                            {errors.email && <p className='error_line'>{errors.email}</p>}
                        </div>
                        <div className='form_group'>
                            <input
                                type='tel'
                                id='phone'
                                name='phone'
                                placeholder='+92 012 3456789'
                                style={errors.phone ? { borderColor: 'red' } : {}}
                                value={formData.phone}
                                onChange={handleChange}
                                ref={formRefs.phone}
                            />
                            {errors.phone && <p className='error_line'>{errors.phone}</p>}
                        </div>
                        <div className='form_group'>
                            <input
                                type='text'
                                id='address'
                                name='address'
                                placeholder='Address*'
                                style={errors.address ? { borderColor: 'red' } : {}}
                                value={formData.address}
                                onChange={handleChange}
                                ref={formRefs.address}
                            />
                            {errors.address && <p className='error_line'>{errors.address}</p>}
                        </div>
                        <div className='form_group'>
                            <select
                                id='gender'
                                name='gender'
                                style={errors.gender ? { borderColor: 'red' } : {}}
                                value={formData.gender}
                                onChange={handleChange}
                                ref={formRefs.gender}
                            >
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                            </select>
                            {errors.gender && <p className='error_line'>{errors.gender}</p>}
                        </div>
                        <div className='form_group'>
                            <textarea
                                id='skills'
                                name='skills'
                                placeholder='Skills*'
                                style={errors.skills ? { borderColor: 'red' } : {}}
                                value={formData.skills}
                                onChange={handleChange}
                                ref={formRefs.skills}
                            />
                            {errors.skills && <p className='error_line'>{errors.skills}</p>}
                        </div>
                        <div
                            className='drop_zone'
                            style={errors.resume ? { borderColor: 'red' } : {}}
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                            onClick={handleDropZoneClick}
                            ref={formRefs.resume}
                        >
                            <input
                                type='file'
                                accept='.docx, .pdf'
                                id='resume'
                                name='resume'
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <div className='drop_zone_text'>
                                {formData.resume ? <p className='file_name'>{formData.resume.name}</p> : <p>Drag and drop file (.docx or .pdf) here, or</p>}
                                <button className='drop_btn' type='button'>Upload CV/Resume (less than 2MB)</button>
                            </div>
                        </div>
                        {errors.resume && <p className='error_line'>{errors.resume}</p>}
                        <button type='submit'>Submit Application</button>
                    </form>
                </div>
            </div >
        </div >
    );
};

export default ApplyForJob;
