import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useScroll, useTransform, motion, useAnimation, useInView } from 'framer-motion';
import { useRef } from 'react';

import AccordionItem from '../../components/accordion_item/accordion_item';
import './Home.css'
import AnimatedImage from '../../components/animated_Image/AnimatedImage';
import SectionTitle from '../../components/section_title/SectionTitle';
import Project from '../../components/project/Project';
import App from '../../components/customers/Customers';
import UseCases from '../../components/use_cases/UseCases';
import AboutCards from '../../components/aboutCards/AboutCards';

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openIndex, setOpenIndex] = useState(null);
  const container = useRef(null)
  const controls = useAnimation();

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end']
  })

  const scale = useTransform(scrollYProgress, [0, 1], [1, -0.2])
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <motion.div ref={container} className='home_page'>
      <motion.section className='hero' style={{ scale }}>
        <div className='hero_container'>
          <h1 className='hero_title'>AI in Operational Workflows</h1>
          <img className='hero_img' src='/assets/hero.jpg' alt='AI' />
        </div>
        <div className='explore_now'>
          <div className='explore_now_wrapper'>
            <Link to={'/projects'}>Explore Now</Link>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </motion.section>

      <section className='flow'>
        <div className='work_flow'>
          <p>Acquire · Plan · Assemble</p>
          <div className='scroll'>
            <FontAwesomeIcon icon={faArrowDown} className='arrow_down' />
            Scroll
          </div>
        </div>
      </section>

      <section className='about_section'>
        <div className='about_wrapper'>
          <SectionTitle start={'01'} end={'06'} title={'About Us'} />
          <div className='about_detail'>
            <p>MISSION & GOALS</p>
            <h3>
              At Neurooceans AI, we specialize in providing top-notch AI and web development services.
              goal is to help businesses grow and become more efficient with our innovative technology
              solutions. Our team of skilled engineers and developers create custom solutions tailored
              to meet the unique needs of each client. We are experts in machine learning, deep learning,
              natural language processing, computer vision, predictive analytics, and AI chatbot development.
              We also offer complete web development services to ensure a smooth online experience.
              At Neurooceans AI, we are dedicated to excellence, innovation, and customer satisfaction,
              helping businesses succeed in the digital world.
            </h3>
            <Link to={'/about'} className='round_btn'>
              Read More
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <span className='hr'></span>

      <section className='about_section projects'>
        <div className='about_wrapper'>
          <SectionTitle start={'02'} end={'06'} title={'Featured Projects'} />
          <Project />
        </div>
      </section>
      <Link to={'/projects'} className='project_round_btn round_btn'>
        View All Projects
        <FontAwesomeIcon icon={faArrowRight} />
      </Link>
      <span className='hr'></span>

      <AboutCards />

      <section className='about_section about2'>
        <div className='about_wrapper'>
          <SectionTitle start={'04'} end={'06'} title={'Make a Difference'} />
          <div className='about_detail'>
            <p>
              NeuroOceans uses new ideas and technology to solve problems and make life better.
              By working with advanced AI and web development, we aim to help people and communities
              around the world. Join us in building a better, more connected future through AI innovation
              and web development excellence.
            </p>
            <span className='hr'></span>
            <h3>
              We believe in giving people the tools and knowledge they need to succeed.
              Through our projects, we help communities learn new skills and grow in the digital age.
              NeuroOceans is dedicated to supporting growth and opportunity for everyone through digital
              education and community empowerment.
            </h3>
            <span className='hr'></span>
            <p>
              Sustainability and social responsibility are core values at NeuroOceans.
              We are dedicated to creating solutions that not only drive progress but also promote ethical practices.
              By integrating sustainable methods and prioritizing social responsibility, we aim to make a positive,
              lasting impact on the world with eco-friendly technology and responsible innovation.
            </p>
            <Link to={'/about'} className='round_btn'>
              Read More
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>

      <section className='cases_section'>
        <UseCases />
      </section>
      <span className='hr'></span>

      <section className='about_section process'>
        <div className='about_wrapper'>
          <SectionTitle start={'06'} end={'06'} title={'Why Choose NeuroOceans AI'} detail={''} />
          <div className='about_detail process_details'>
            <div className="accordion">
              <AccordionItem
                id_1="0"
                id_2="1"
                title_1="Quality First"
                title_2="Company"
                p_1="At NeuroOceans AI, we specialize in delivering top-tier software solutions and services tailored to meet the unique needs of our clients. Our commitment is to ensure that every investment in IT yields tangible business value. We pride ourselves on fostering healthy project processes, driven by professional and motivated teams, facilitating effective communication between NeuroOceans AI, your teams, and stakeholders."
                p_2=""
                index={0}
                isOpen={openIndex === 0}
                onToggle={toggleAccordion}
              />

              <AccordionItem
                id_1="0"
                id_2="2"
                title_1="Fast"
                title_2="Development"
                p_1="At NeuroOceans AI, we've curated a suite of management and technological strategies enabling us to roll out fresh application iterations every 2–3 weeks, with the flexibility to implement minor updates multiple times daily. Our success stems from an iterative development ethos, bolstered by Agile project management methodologies, and underpinned by cloud-native architectures and cutting-edge infrastructure management techniques."
                p_2=""
                index={1}
                isOpen={openIndex === 1}
                onToggle={toggleAccordion}
              />

              <AccordionItem
                id_1="0"
                id_2="3"
                title_1="Top-Notch"
                title_2="Security"
                p_1="With 10 years of dedicated service in the realm of IT, NeuroOceans AI stands as a beacon of reliability and security. Throughout our journey, not a single breach has compromised the trust of our clients. We pledge unwavering protection for our customers' invaluable data. At NeuroOceans AI, we spare no effort in fortifying our defenses. Our strategy hinges on diligent IT asset management, fostering a secure environment, enforcing strict access controls, and employing formidable encryption algorithms. Continuous monitoring of our IT infrastructure is paramount, ensuring swift detection and response to any potential threats."
                p_2=""
                index={2}
                isOpen={openIndex === 2}
                onToggle={toggleAccordion}
              />

              <AccordionItem
                id_1="0"
                id_2="4"
                title_1="Commitment"
                title_2="to Excellence"
                p_1="At NeuroOceans AI, we delve beyond conventional strategies to elevate customer satisfaction and enhance performance through our cutting-edge IT services and innovative solutions. Our proficiency across four crucial dimensions: Customer References, Delivery Excellence (Awards & Certifications), Programs for Innovation, and Corporate Social Responsibility."
                p_2=""
                index={3}
                isOpen={openIndex === 3}
                onToggle={toggleAccordion}
              />

            </div>
          </div>
        </div>
      </section>
      <span className='hr'></span>

      {/* <section className='about_section blogs'>
        <div className='about_wrapper'>
          <SectionTitle start={'07'} end={'06'} title={'Trending Topics'} />
          <div className='about_detail'>
            <div className='blog'>
              <div className='blog_image'>
                <img src='/assets/hero.jpg' alt='' />
              </div>
              <div className='blog_detail'>
                <div className='blog_header'>
                  <h4>PROJECT ANNOUNCEMENT</h4>
                  <p>MAY 29, 2024</p>
                </div>
                <div className='blog_desc'>
                  <p>
                    Our new project PlayPark is a natural, organic playground designed for children aged 5-12 years.
                    The project aims to foster physical and psychological development through play, utilizing sustainable
                    materials and natural elements. The playground promotes creativity, teamwork, and community engagement,
                    offering children a safe space to play and socialize.
                  </p>
                </div>
                <Link to={'/'} className='round_btn'>
                  Read More
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section> */}

      <App />

    </motion.div >

  )
}

export default Home
