import React from 'react'

import HeroSection from '../../components/heroSection/Herosection';
import { backend, frontend, devOps, tools, databases } from '../../jsondata/technologies';
import './Technologies.css'
import '../home/Home.css'

const Technologies = () => {
  return (
    <div className='platforms_page'>
      <HeroSection title="Technologies" />

      <div className='platforms_container'>
        <div className='header'>
          <h1 className="page_title">Our Capabilities and Technological Expertise</h1>
          <div className='border'></div>
          <p className="subtitle"> We use the latest technological solutions to offer you premium, high value services. </p>
        </div>

        <div className="technology">

          <div className="image_container">
            <img src={"/assets/backend_development.webp"} alt="tech-img" />
          </div>

          <div className="technology_details">
            <h1 className="technology_heading">{backend.title}</h1>
            <div className='border'></div>
            <p className="technology_subheading"> {backend.desc} </p>

            <div className="tech_row">
              {backend.logos.map((backend, index) => (
                <div className="tech_col" key={index}>
                  <div className='tech_img_container'>
                    <img src={backend.icon} alt={`${backend.name}_logo`} />
                  </div>
                  <p className="para">{backend.name}</p>
                </div>
              ))}

            </div>
          </div>
        </div>

        <div className="technology reverse">

          <div className="image_container">
            <img src={"/assets/frontend_development.webp"} alt="tech-img" />
          </div>

          <div className="technology_details">
            <h1 className="technology_heading">{frontend.title}</h1>
            <div className='border'></div>
            <p className="technology_subheading"> {frontend.desc} </p>

            <div className="tech_row">
              {frontend.logos.map((frontend, index) => (
                <div className="tech_col" key={index}>
                  <div className='tech_img_container'>
                    <img src={frontend.icon} alt={`${frontend.name}_logo`} />
                  </div>
                  <p className="para">{frontend.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="technology">

          <div className="image_container">
            <img src={"/assets/devops_logo.webp"} alt="tech-img" />
          </div>

          <div className="technology_details">
            <h1 className="technology_heading">{devOps.title} </h1>
            <div className='border'></div>
            <p className="technology_subheading"> {devOps.desc} </p>

            <div className="tech_row">
              {devOps.logos.map((devOps, index) => (
                <div className="tech_col" key={index}>
                  <div className='tech_img_container'>
                    <img src={devOps.icon} alt={`${devOps.name}_logo`} />
                  </div>
                  <p className="para">{devOps.name}</p>
                </div>
              ))}

            </div>
          </div>
        </div>

        <div className="technology reverse">

          <div className="image_container">
            <img src={"/assets/tools_img.jpg"} alt="tech-img" />
          </div>

          <div className="technology_details">
            <h1 className="technology_heading">AI Toolset</h1>
            <div className='border'></div>
            <p className="technology_subheading"> We provide an advanced AI toolset with cutting-edge solutions to empower your business with intelligent automation and data-driven insights. </p>

            <div className="tech_row">
              {tools.logos.map((tools, index) => (
                <div className="tech_col" key={index}>
                  <div className='tech_img_container'>
                    <img src={tools.icon} alt={`${tools.name}_logo`} />
                  </div>
                  <p className="para">{tools.name}</p>
                </div>
              ))}

            </div>
          </div>
        </div>

        <div className="technology">

          <div className="image_container">
            <img src={"/assets/database.webp"} alt="tech-img" />
          </div>

          <div className="technology_details">
            <h1 className="technology_heading">{databases.title}</h1>
            <div className='border'></div>
            <p className="technology_subheading"> {databases.desc} </p>

            <div className="tech_row">
              {databases.logos.map((databases, index) => (
                <div className="tech_col" key={index}>
                  <div className='tech_img_container'>
                    <img src={databases.icon} alt={`${databases.name}_logo`} />
                  </div>
                  <p className="para">{databases.name}</p>
                </div>
              ))}

            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Technologies
