import React from 'react'
import { Link } from 'react-router-dom'
import './UseCases.css'

const CasesCard = ({slug, index, image, title, tags, desc}) => {
  return (
    <Link to={`/case-study/${slug}`} key={index} class="case_study_card">
      <div class="image_container">
        <div class="case_study_badge">Case Study</div>
        <img src={image} alt="Case Study" />
        <div class="read_more_circle">Read</div>
      </div>
      <div class="case_study_content">
        <div class="company_name">{title}</div>
        {/* <div class="title">{member.title}</div> */}
        <div class="tags">{tags}</div>
        <div><p class="description">{desc}</p></div>
      </div>
    </Link>
  )
}

export default CasesCard