import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

import projects from '../../jsondata/projectsData.json'

const Project = () => {
    
  return (
    <div className='project_content'>
      {projects.slice(0, 3).map((project, index) => (
        <div className={`about_detail ${index % 2 === 1 ? 'row_reverse' : ' '}`} key={project.id}>
          <div className='projects_image'>
            <img src={project.image} alt={project.title} />
          </div>
          <div className='projects_detail'>
            <div className='detail_text'>
              <p className='project_category'>
                <span>Category:</span> {project.category}
              </p>
              <h4>{project.title}</h4>
              <p>{project.description}</p>
            </div>
            <Link to={project.href} className='round_btn' target="_blank" rel="noopener noreferrer">
              Preview
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Project