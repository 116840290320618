import React from 'react'
import './SectionTitle.css'

const SectionTitle = ({start, end, title, detail}) => {
  return (
    <div className='section_title'>
        <div className='count'>
            <div className='count_start'>{start}</div>
            <span className='center_line'></span>
            <div className='count_start'>{end}</div>
        </div>
        <h2>{title}</h2>
        <p>{detail ? detail : ''}</p>
    </div>
  )
}

export default SectionTitle
