import React from 'react';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import services from '../../jsondata/servicesData.json'
import "./AboutCards.css"

const CardSlider = () => {
    const classes = ["card_1", "card_2", "card_3", "card_4"];
    return (
        <>
            <section className="cardslider_section">
                <div className='cards_slider'>
                    <div className="swiper_outer_div">
                        <div className="swiper_body">
                            <div className="swiper_inner_div">
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    centeredSlides={false}
                                    spaceBetween={50}
                                    grabCursor={true}
                                    navigation={true}
                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        480: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Navigation]}
                                    speed={1000}
                                    className="mySwiper"
                                >
                                {
                                  services.map((service,index) => (
                                    <SwiperSlide key={index}>
                                            <Link to={'/services'} className={`card ${classes[index % classes.length]}`}>
                                                <div className='card_content'>
                                                    <h1>{service.title}</h1>
                                                    <p>Discover more about our services</p>
                                                    <div className='arrow_container'>
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </div>
                                                </div>
                                            </Link>
                                    </SwiperSlide>
                                  ))
                                }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default CardSlider