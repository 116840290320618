import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faLaptopCode, faPhone, faEnvelope, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

import HeroSection from '../../components/heroSection/Herosection';
import servicesData from '../../jsondata/servicesData2.json'
import "./Services.css"

const Services = () => {
    const [activeService, setActiveService] = useState(0);

    const serviceClickHandler = (index) => {
        setActiveService(servicesData[index]);
    };

    useEffect(() => {
        setActiveService(servicesData[0]);
    }, []);

    return (
        <div className='services_page'>
            <HeroSection title="Services" />
            <div className='services_container'>
                <div className='header'>
                    <h1 className="page_title">Our Professional Services</h1>
                    <div className='border'></div>
                    <p className="subtitle"> Delivering Expert Solutions Tailored to Your Needs with a Commitment to Excellence and Innovation </p>
                </div>
                <div className='wrapper'>
                    <div className='left_side'>
                        <ul className="services_menu">
                            {servicesData.map((service, index) => (
                                <li key={service.id} className={`service ${activeService === service ? 'active' : ''}`} onClick={() => serviceClickHandler(index)}>
                                    <div className='icon_container'><FontAwesomeIcon icon={faAnglesRight} /></div>
                                    <p className='service_name'>{service.name}</p>
                                </li>
                            ))}
                        </ul>

                        <div className='about_us'>
                            <h2>About Us</h2>
                            <img src='/assets/know-us.jpg' alt='about-img' />
                            <p>
                                NeuroOeans AI is a software service provider that specializes in providing Artificial Intelligence (AI) solutions to businesses across various industries. Our team of experts leverages the latest advancements in AI technology to deliver innovative solutions that help companies make better decisions, increase efficiency, and stay ahead of the competition
                            </p>
                        </div>

                        <div className='help_wrapper'>
                            <div className='help'>
                                <FontAwesomeIcon className='laptop' icon={faLaptopCode} />
                                <h2>How Can We Help?</h2>
                                <p>If you need any helps,plese feel free to contact us.</p>
                                <div className='contact phone'>
                                    <FontAwesomeIcon icon={faPhone} />
                                    <Link to={'tel:%20+923414742499'}>+92 341 4742499</Link>
                                </div>
                                <div className='contact'>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <Link to={'mailto:hr@neurooceans.ai'}>hr@neurooceans.ai</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='right_side'>
                        <div className="tab_menu">
                            {servicesData.map((service, index) => (
                                <div
                                    key={index}
                                    className={`tab ${activeService === service ? 'active' : ''}`}
                                    onClick={() => serviceClickHandler(index)}
                                    style={{ marginLeft: index === 0 ? 0 : undefined }}
                                >
                                    {service.name}
                                </div>
                            ))}
                        </div>

                        {activeService && (
                            <div className='content_wrapper active'>
                                <div className='content_top'>
                                    <div className='content_text'>
                                        <h2>{activeService.name}</h2>
                                        <p>{activeService.desc}</p>
                                    </div>
                                    {/* <img src={'/assets/hero.jpg'} alt='hero' /> */}
                                </div>
                                <div className='content_bottom'>
                                    <div className='content_text'>
                                        <ul>
                                            {activeService.features.map((feature, idx) => (
                                                <li key={idx}><FontAwesomeIcon icon={faMinus} />{feature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <img src={activeService.image1} alt={activeService.name} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services