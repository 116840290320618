import React, { useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import './accordion_item.css'


const AccordionItem = ({ id_1, id_2, title_1, title_2, p_1, p_2, index, isOpen, onToggle }) => {
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        } else {
            contentRef.current.style.maxHeight = '0';
        }
    }, [isOpen]);

    return (
        <div className="accordion_item">
            <div className="accordion_header" onClick={() => onToggle(index)}>
                <div className="heading_wrapper">

                    <div className="accordion_id">

                        <div className="accordion_id_n1">
                            <span>{id_1}</span>
                        </div>
                        <div className="accordion_id_n2">
                            <span>{id_2}</span>
                        </div>

                        <div className='accordion_id_abs'>
                            <div className="accordion_id_m1">
                                <span>{id_1}</span>
                            </div>
                            <div className="accordion_id_m2">
                                <span>{id_2}</span>
                            </div>
                        </div>
                    </div>

                    <div className="accordion_title">

                        <div className="accordion_title_n1">
                            <span>{title_1}</span>
                        </div>
                        <div className="accordion_title_n2">
                            <span>{title_2}</span>
                        </div>

                        <div className='accordion_title_abs'>
                            <div className="accordion_title_m1">
                                <span>{title_1}</span>
                            </div>
                            <div className="accordion_title_m2">
                                <span>{title_2}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <FontAwesomeIcon
                    className='accordion_toggle'
                    icon={isOpen ? faMinus : faPlus}
                    style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)' }}
                />
            </div>
            <div
                className="accordion_content" ref={contentRef} >
                <p className='top_content'>{p_1}</p>
                <p>{p_2}</p>
            </div>
        </div>
    );
};

export default AccordionItem