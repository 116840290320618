import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SectionTitle from '../section_title/SectionTitle'
import '../../pages/home/Home.css'
import './UseCases.css'
import CasesCard from './CasesCard';

const UseCases = () => {
  const [caseData, setCaseData] = useState([]);

  useEffect(() => {
    // Fetch data from API when component mounts
    const fetchData = async () => {
      try {
        const response = await fetch("https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/case-studies/");
        const data = await response.json();
        setCaseData(data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Cleanup function
    return () => {
      // Any cleanup code (if needed)
    };
  }, []); // Empty dependency array to execute only once when component mounts


  return (
    <section class="about_section case_studies">
      <div className='about_wrapper'>
        <SectionTitle start={'05'} end={'06'} title={'Case Studies'} />
        <div className="card_wrapper">
            {caseData.slice(0, 2).map((member, index) => (
               <CasesCard 
                   slug = {member.slug}
                   index = {index}
                   image = {member.image}
                   title = {member.title}
                   tags = {member.tags}
                   desc = {member.meta_description}
               />
            ))}

          <Link to={'/case-studies'} className='round_btn'>
            View All Case Studies
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default UseCases