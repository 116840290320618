import React from 'react'
import { Link } from 'react-router-dom'

import HeroSection from '../../components/heroSection/Herosection';
import projects from '../../jsondata/projectsData.json'
import './Projects.css'
import '../home/Home.css'

const Projects = () => {

  return (
    <div className='project_page'>
      <HeroSection title="Projects" />
      <div className='project_container'>
        <div className='header'>
          <h1 className="page_title">Projects We Have Completed</h1>
          <div className='border'></div>
          <p className="subtitle"> Showcasing Our Successful Implementations and Innovative Solutions Across Diverse Industries and Technologies </p>
        </div>

        <div className='wrapper'>
          {projects.map((project,index) => (
            <div className='project_wrapper' key={project.id}>
              <Link to={project.href}>
                <div className='project'>
                  <img src={project.image} alt={project.title}/>
                  <div className='content'>
                    <p>{project.category}</p>
                    <h2>{project.title}</h2>
                  </div>
                </div>
              </Link>
            </div>
           ))}
        </div>
      </div>
    </div>
  )
}

export default Projects