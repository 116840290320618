import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCells, faList, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import HeroSection from '../../components/heroSection/Herosection';
import jobs from '../../jsondata/job_post.json'
import './Career.css';
import { Link } from 'react-router-dom';


const Career = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchValue, setSearchValue] = useState("")
  const [listView, setListView] = useState(false)

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(cat => cat !== category)
        : [...prev, category]
    );
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value)
  }

  const toggleListView = () => {
    setListView(true)
  }

  const toggleGridView = () => {
    setListView(false)
  }

  const categories = jobs.reduce((acc, job) => {
    acc[job.Category] = (acc[job.Category] || 0) + 1;
    return acc;
  }, {});

  const filteredJobs = jobs.filter(job => {
    const matchesCategory = selectedCategories.length ? selectedCategories.includes(job.Category) : true;
    const matchesSearch = job.Title.toLowerCase().includes(searchValue.toLowerCase()) ||
      job.Category.toLowerCase().includes(searchValue.toLowerCase());

    return matchesCategory && matchesSearch
  })


  return (
    <div className='career_page'>
      <HeroSection title="Career" />
      <div className='career_container'>
        <div className='header'>
          <h1 className="page_title">Join Our Inclusive and Innovative Team</h1>
          <div className='border'></div>
          <p className="subtitle">At NeuroOceans AI, we are dedicated to fostering a culture of inclusivity and diversity, where every team member can thrive. Join us in our mission to make AI more accessible for all.</p>
        </div>

        <hr className='position_separator' />
        <h1 className="position_title">JOB POSTS</h1>
        <div className='position_wrapper'>

          <div className='category_wrapper'>
            <p><b>SHOW</b> RESULTS BY</p>
            <div className='category'>
              <ul>

                {Object.keys(categories).map((category) => (
                  <li className='category_name' key={category}>
                    <input
                      className='check_box'
                      type="checkbox"
                      id={category}
                      onChange={() => handleCategoryChange(category)}
                    />
                    <label htmlFor={category}> {category} ({categories[category]})</label>
                  </li>
                ))}

              </ul>
            </div>
          </div>

          <div className='post_wrapper'>
            <div className='top_bar'>
              <p className='bar_title'><b>ALL</b> POSITIONS</p>
              <p className='total_jobs'>{filteredJobs.length} Job(s)</p>
              <div className='bar_icon_container'>
                <FontAwesomeIcon className={`bar_icon ${listView ? 'black' : ''}`} onClick={toggleGridView} icon={faTableCells} />
                <FontAwesomeIcon className={`bar_icon ${listView ? '' : 'black'}`} onClick={toggleListView} icon={faList} />
              </div>
              <div className='search_wrapper'>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchValue}
                  className='search_input'
                />
                <FontAwesomeIcon className='search_icon' icon={faMagnifyingGlass} />
              </div>
            </div>
            <div className={`posts ${listView ? 'list_view' : ''}`}>

              {filteredJobs.map((job, index) => (
                <Link to={`/jobs/${job.Slug}`} className='post' key={index}>
                  {/* {job.featured && <div className='featured'>Featured</div>} */}
                  <p className='job_title'>{job.Title}</p>
                  <p className='job_positions'>{job.NoOfPositions} Position(s)</p>
                  <hr className='job_separator' />
                  <p className='job_category'>{job.Category}</p>
                  <p className='job_location'>{job.Location}</p>
                </Link>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;