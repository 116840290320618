import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScroll(true);
    }
    else {
      setScroll(false);
    }
  }

  const handleHamburgerClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no_scroll');
    } else {
      document.body.classList.remove('no_scroll');
    }
  }, [isOpen]);

  // close navbar toggler when navigate to new page
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <div className={`navbar ${isOpen ? "" : scroll ? "" : "un_scrolled"}`}>
      <div className='navbar_wrapper'>
        <div className={`top_navbar ${isOpen ? 'open' : ''}`}>
          <div className='left'>
            <p className='logo'>
              <img src='/assets/NeuroOceans.png' alt='logo' />
              NEUROOCEANS.AI
            </p>
          </div>
          <div className='top_right'>
            <Link to={'/contact'} className='nav_btn'>
              <FontAwesomeIcon icon={faArrowLeft} to={'/contact'} />
              <span>Get Started</span>
            </Link>
            <div className={`hamburger ${isOpen ? "cross" : ""}`} onClick={handleHamburgerClick}>
              <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                <path className="line line1" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 8h24' />
                <path className="line line2" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 16h24' />
                <path className="line line3" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 24h24' />
              </svg>
            </div>
            {/* <FontAwesomeIcon icon={isOpen ? faXmark : faBars} className='hamburger' onClick={handleHamburgerClick} /> */}
          </div>
        </div>
        {isOpen && (
          <div className='toggler_wrapper'>
            <div className='navbar_toggler'>
              <div className='navigation'>
                <p className='nav_heading'>NAVIGATION</p>
                <ul>
                  <li className='nav_item'><Link to={'/'}>Home</Link></li>
                  <li className='nav_item'><Link to={'/about'}>About</Link></li>
                  <li className='nav_item'><Link to={'/projects'}>Projects</Link></li>
                  <li className='nav_item'><Link to={'/technologies'}>Technologies</Link></li>
                  <li className='nav_item'><Link to={'/services'}>Services</Link></li>
                  <li className='nav_item'><Link to={'/industries'}>Industries</Link></li>
                  <li className='nav_item'><Link to={'/case-studies'}>Case Studies</Link></li>
                  <li className='nav_item'><Link to={'/career'}>Career</Link></li>
                  <li className='nav_item'><Link to={'/contact'}>Contact</Link></li>

                </ul>
              </div>

              {/* <div className='news'>
                <div className='top_news'>
                  <p className='nav_heading'>LATEST NEWS</p>

                  <div className='news_body'>
                    <div className='news_wrapper'>
                      <Link to={'/'} className='news_1'>
                        November 23, 2023
                      </Link>
                      <Link to={'/'} className='news_2'>
                        September 27, 2023
                      </Link>
                    </div>

                  </div>
                </div>
              </div> */}

              {/* <div className='offering'>
                <p className='nav_heading'>OFFERINGS</p>

              </div> */}

            </div>
          </div>
        )
        }
        <div className='bottom_navbar'>
          <Link to={'/'}>Home</Link>
          <Link to={'/about'}>About</Link>
          <Link to={'/projects'}>Projects</Link>
          <Link to={'/technologies'}>Technologies</Link>
          <Link to={'/services'}>Services</Link>
          <Link to={'/industries'}>Industries</Link>
          <Link to={'/case-studies'}>Case Studies</Link>
          <Link to={'/career'}>Career</Link>
          <Link to={'/contact'}>Contact</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
