import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTiktok, faGithub, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

import './Contact.css'
import HeroSection from "../../components/heroSection/Herosection";
import { Link } from "react-router-dom";

const ThankYou = () => {

    return (
        <div className="thankYou_page">
            <div className='thankYou_container'>
                <div className='header'>
                    <h1 className="page_title">Thank You For Sending Us Message!</h1>
                    <img className="thankYou_img" src='/assets/msg_confermation.gif' />
                    <p className="subtitle"> We have received your message, will contact you soon. </p>
                    <Link to={'/'} className='home_btn'>Go to Home Page </Link>

                    {/* <hr className="saparator" />

                    <h2 className='heading'>Also Follow Us On</h2>
                    <div className='logos_wrapper'>

                        <a className="logo_container" href="https://github.com/NeurOOceans">
                            <FontAwesomeIcon className="logo" icon={faGithub} />
                        </a>
                        <a className="logo_container" href="https://twitter.com/neurooceansai">
                            <FontAwesomeIcon className="logo" icon={faXTwitter} />
                        </a>
                        <a className="logo_container" href="https://pk.linkedin.com/company/neurooceans-ai">
                            <FontAwesomeIcon className="logo" icon={faLinkedinIn} />
                        </a>
                        <a className="logo_container" href="https://www.tiktok.com/@neurooceans.ai">
                            <FontAwesomeIcon className="logo" icon={faTiktok} />
                        </a>
                        <a className="logo_container" href="https://www.instagram.com/neurooceansai/">
                            <FontAwesomeIcon className="logo" icon={faInstagram} />
                        </a>

                    </div> */}
                </div>

            </div>
        </div>
    )
}

export default ThankYou