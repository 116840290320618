export const backend = {
  "title": "Back-end Development",
  "desc": "We provide robust backend development services and solutions to ensure your business operates seamlessly and efficiently.",
  "logos": [
    {
      "id": 1,
      "name": "Django",
      "icon": "/assets/django_logo.svg"
    },
    {
      "id": 2,
      "name": "Flask",
      "icon": "/assets/flask_logo.svg"
    },
    {
      "id": 3,
      "name": "Express.js",
      "icon": "/assets/express_logo.svg"
    },
    {
      "id": 4,
      "name": "Spring Boot",
      "icon": "/assets/springboot_logo.svg"
    },
    {
      "id": 5,
      "name": "Laravel",
      "icon": "/assets/laravel_logo.svg"
    },
    {
      "id": 6,
      "name": "Ruby on Rails",
      "icon": "/assets/ruby-on-rails_logo.svg"
    },
    {
      "id": 7,
      "name": "ASP.NET Core",
      "icon": "/assets/aspnet_logo.svg"
    },
    {
      "id": 8,
      "name": "FastAPI",
      "icon": "/assets/fastapi_logo.svg"
    },
    {
      "id": 9,
      "name": "Phoenix",
      "icon": "/assets/phoenix_logo.svg"
    }
  ]
}

export const frontend = {
  "title": "Front-end Development",
  "desc": "We deliver cutting-edge frontend development services and solutions to create engaging and responsive user experiences for your business.",
  "logos": [
    {
      "id": 1,
      "name": "React",
      "icon": "/assets/react_logo.svg"
    },
    {
      "id": 2,
      "name": "Angular",
      "icon": "/assets/angular_logo.svg"
    },
    {
      "id": 3,
      "name": "Vue.js",
      "icon": "/assets/vue-js_logo.svg"
    },
    {
      "id": 4,
      "name": "Svelte",
      "icon": "/assets/svelte_logo.svg"
    },
    {
      "id": 5,
      "name": "Ember.js",
      "icon": "/assets/ember_logo.svg"
    },
    {
      "id": 6,
      "name": "Backbone.js",
      "icon": "/assets/backbonejs_logo.svg"
    },
    {
      "id": 7,
      "name": "Preact",
      "icon": "/assets/preact_logo.svg"
    },
    {
      "id": 8,
      "name": "Alpine.js",
      "icon": "/assets/alpine-js_logo.svg"
    },
    {
      "id": 9,
      "name": "Materializecss",
      "icon": "/assets/materializecss_logo.svg"
    }
  ]
}

export const devOps = {
  "title": "Development Operations (Dev Ops)",
  "desc": "We offer comprehensive DevOps services and solutions to streamline your development processes and enhance operational efficiency.",
  "logos": [
    {
      "id": 1,
      "name": "Docker",
      "icon": "/assets/docker_logo.svg"
    },
    {
      "id": 2,
      "name": "GitLab",
      "icon": "/assets/gitlab_logo.svg"
    },
    {
      "id": 3,
      "name": "GitHub Actions",
      "icon": "/assets/github_logo.svg"
    },
    {
      "id": 4,
      "name": "Jenkins",
      "icon": "/assets/jenkins_logo.svg"
    },
    {
      "id": 5,
      "name": "Kubernetes",
      "icon": "/assets/kubernetes_logo.svg"
    },
    {
      "id": 7,
      "name": "AWS",
      "icon": "/assets/aws_logo.svg"
    },
    {
      "id": 8,
      "name": "Azure",
      "icon": "/assets/azure_logo.svg"
    },
    {
      "id": 11,
      "name": "Jira",
      "icon": "/assets/jira_logo.svg"
    },
    {
      "id": 12,
      "name": "Git",
      "icon": "/assets/git_logo.svg"
    }

  ]
}

export const tools = {
  "title": "Development Operations (Dev Ops)",
  "desc": "We offer comprehensive DevOps services and solutions to streamline your development processes and enhance operational efficiency.",
  "logos": [
    {
      "id": 1,
      "name": "TensorFlow",
      "icon": "/assets/tensorflow_logo.svg"
    },
    {
      "id": 2,
      "name": "PyTorch",
      "icon": "/assets/pytorch_logo.svg"
    },
    {
      "id": 3,
      "name": "Keras",
      "icon": "/assets/keras_logo.svg"
    },
    {
      "id": 4,
      "name": "Scikit-learn",
      "icon": "/assets/scikit-learn_logo.svg"
    },
    {
      "id": 5,
      "name": "OpenCV",
      "icon": "/assets/opencv_logo.svg"
    },
    {
      "id": 6,
      "name": "NumPy",
      "icon": "/assets/numpy_logo.svg"
    },
    {
      "id": 7,
      "name": "OpenAI GPT",
      "icon": "/assets/openai_logo.svg"
    },
    {
      "id": 8,
      "name": "RapidMiner",
      "icon": "/assets/rapidminer_logo.svg"
    },
    {
      "id": 9,
      "name": "DataRobot",
      "icon": "/assets/datarobot_logo.svg"
    }
  ]
}


export const databases = {
  "title": "Data Mastery",
  "desc": "We offer reliable database services and solutions to manage your data efficiently and securely, ensuring high performance and scalability.",
  "logos": [
    {
      "id": 1,
      "name": "MySQL",
      "icon": "/assets/mysql_logo.svg"
    },
    {
      "id": 2,
      "name": "PostgreSQL",
      "icon": "/assets/postgresql_logo.svg"
    },
    {
      "id": 3,
      "name": "SQLite",
      "icon": "/assets/sqlite_logo.svg"
    },
    {
      "id": 4,
      "name": "MongoDB",
      "icon": "/assets/mongodb_logo.svg"
    },
    {
      "id": 5,
      "name": "Microsoft SQL",
      "icon": "/assets/microsoft-sqlserver_logo.svg"
    },
    {
      "id": 6,
      "name": "Oracle DB",
      "icon": "/assets/oracle-database_logo.svg"
    },
    {
      "id": 7,
      "name": "Redis",
      "icon": "/assets/redis_logo.svg"
    },
    {
      "id": 8,
      "name": "MariaDB",
      "icon": "/assets/mariadb_logo.svg"
    },
    {
      "id": 9,
      "name": "IBM Db2",
      "icon": "/assets/ibm-db2_logo.svg"
    }
  ]
}