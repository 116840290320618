import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GrBundle, GrTechnology, GrLocation, GrLinkNext } from "react-icons/gr";

import './CaseStudies.css'
import HeroSection from "../../components/heroSection/Herosection";

const CaseDescription = () => {
    const [caseData, setCaseData] = useState(null);
    const [error, setError] = useState(null);
    const { slug } = useParams();

    const renderMarkdown = (content) => {
        if (!content) {
            return null;
        }

        const lines = content.split("\n");

        return lines.map((line, index) => {
            line = line.replace(/\\([*#>])/g, "$1").replace(/\\/g, "");

            // Handle headings
            const headerMatch = line.match(/^(#{1,6})\s*(.*)/);
            if (headerMatch) {
                const headerLevel = headerMatch[1].length;
                const headerText = headerMatch[2].replace(/\s*#+$/g, "");

                return React.createElement(
                    `h${headerLevel}`,
                    { className: `header-level-${headerLevel}`, style: { textAlign: "left" }, key: index },
                    headerText
                );
            }

            // Handle unordered bullet points
            const bulletMatch = line.match(/^(\*|-|•|)\s+(.*)/);
            if (bulletMatch) {
                const bulletText = bulletMatch[2];

                return (
                    <ul key={index} className="bullet_list">
                        <li>{bulletText}</li>
                    </ul>
                );
            }

            // Split the line by '*', and alternate between normal text and bold text
            const parts = line.split("*");
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        return i % 2 === 0 ? part : <span className="lists" key={i}>{part}</span>;
                    })}
                </p>
            );
        });
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/case-studies/${slug}`);
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error('Case not found');
                    } else {
                        throw new Error('Failed to fetch case data');
                    }
                }
                const data = await response.json();
                setCaseData(data);
                setError(null);
            } catch (error) {
                setError(error.message);
                setCaseData(null);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!caseData) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className='casestudies_page'>
            <HeroSection title={caseData.category_name} />

            <div className='casestudies_container'>
                
                <section className="hero_section_of_page">
                    <div className="content">
                        <h2>{caseData.title}</h2>
                    </div>
                </section>

                <section className="data_container">
                    <div className="data_description">
                        <h2>{caseData.quote}</h2>
                        <img src={caseData.quote_logo} alt="company_logo"></img>
                    </div>
                    <div className="services">
                        <ul className="items_case">
                            <li className="items_list">
                                <span className="case_detail">
                                    <GrBundle className="icon" />
                                    <h4>Service</h4>
                                    <GrLinkNext className="connect_icon" />
                                    <p>{caseData.service}</p>
                                </span>
                            </li>
                            <li className="items_list">
                                <span className="case_detail">
                                    <GrTechnology className="icon" />
                                    <h4>Industry</h4>
                                    <GrLinkNext className="connect_icon" />
                                    <p>{caseData.industry}</p>
                                </span>
                            </li>
                            <li className="items_list">
                                <span className="case_detail">
                                    <GrLocation className="icon" />
                                    <h4>Location</h4>
                                    <GrLinkNext className="connect_icon" />
                                    <p>{caseData.location}</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="challange_container">
                    <div className="part1">
                        <section className="experience">
                            <div className="markdown_part2">
                                {renderMarkdown(caseData.case_desc2)}
                            </div>
                        </section>

                        <section className="project_d">
                            <div className="markdown_part1">
                                {renderMarkdown(caseData.case_desc1)}
                            </div>
                        </section>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default CaseDescription