import React from "react";

import "./Customers.css";

const App = () => {
    return (
        <div className="customer_section">
            <h2 className="title">Our Clients</h2>
            <p className="subtitle">Fueling success through valued partnerships. Join our satisfied client community, where tailor-made AI solutions drive tangible results and faster growth</p>

            <div className="customer_container">

                <section className="logo_wrapper">
                    <div className="logo_container"><img className="customer_logo" src={'/assets/Arricor.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/basatanwar-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/Desert_Tourism_logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/jsl_logo.svg'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/h1-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/keebler-health-ai-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/gettyimages-logo.svg'} /></div>
                </section>
                <section className="logo_wrapper">
                    <div className="logo_container"><img className="customer_logo" src={'/assets/Arricor.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/basatanwar-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/Desert_Tourism_logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/jsl_logo.svg'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/h1-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/keebler-health-ai-logo.png'} /></div>
                    <div className="logo_container"><img className="customer_logo" src={'/assets/gettyimages-logo.svg'} /></div>
                </section>
            </div>
        </div>
    );
};

export default App
