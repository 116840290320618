import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import "./Technologies.css"



const Technology = () => {
    return (
        <>
            <section className="technology_section">
                <div className="container">

                    <div className="technology">
                        <h2 className="title">Technologies We Work In</h2>
                        <p className="subtitle">We use the latest technological solutions to offer you premium, high value services.</p>
                    </div>
                </div>

                <div className='technology_slider'>
                    <div className="swiper_outer_div">
                        <div className="swiper_body">
                            <div className="swiper_inner_div">
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    centeredSlides={false}
                                    spaceBetween={50}
                                    grabCursor={true}
                                    navigation={true} 
                                    breakpoints={{
                                        300: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        480: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Navigation, Autoplay]}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false
                                    }}
                                    speed={1000}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="technology_details">
                                            <h1 className="technology_heading">Back-end Development</h1>

                                            <div className="tech_row">
                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/django_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/flask_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/express_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/springboot_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/laravel_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/ruby-on-rails_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="technology_details">
                                            <h1 className="technology_heading">Front-end Development</h1>

                                            <div className="tech_row">
                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/react_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/angular_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/vue-js_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/svelte_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/ember_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/backbonejs_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="technology_details">
                                            <h1 className="technology_heading">Dev Ops</h1>

                                            <div className="tech_row">
                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/docker_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/gitlab_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/github_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/jenkins_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/kubernetes_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/aws_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="technology_details">
                                            <h1 className="technology_heading">AI Toolset</h1>

                                            <div className="tech_row">
                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/tensorflow_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/pytorch_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/keras_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/scikit-learn_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/opencv_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/numpy_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="technology_details">
                                            <h1 className="technology_heading">Data Mastery</h1>

                                            <div className="tech_row">
                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/mysql_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/postgresql_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/sqlite_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/mongodb_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/microsoft-sqlserver_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            <div className="tech_col">
                                                <div className='tech_img_container'>
                                                <img src={"/assets/oracle-database_logo.svg"} alt="tech-img" />
                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Technology